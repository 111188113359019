.header {
  &-container {
    height: 170px;
  }

  &-white-part-container {
    height: 65px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  &-selected-item {
    background: #f0f1f2;
    border-radius: 10px;
  }

  &-icons {
    width: 20px;
    height: 20px;
  }

  &-notif-bubble {
    width: 15px;
    height: 15px;
    background: #FF0000;
    border-radius: 8px;
    margin-top: -15px;
    
    @media (max-width: 600px) {
      margin-top:0px;
      left: -15px;
      bottom: -5px;
    }
  }

  &-logo {
    height: 45px;
  }

  &-green-line {
    background: #2EA0B4;
    height: 1px;
    width: 92%;
  }

  &-menu {
    width: 40px;
    height: 40px;
  }

  &-dropdown {
    width: calc(100% - 14px);
    height: 189px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px 0px rgba(147, 147, 147, 0.5);

    &-container {
      width: 225px;
      height: 213px;
      position: absolute;
      top: calc(100% + 5px);
      right: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-arrow {
      height: 15px;
      width: 15px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: white;
      margin-right: 40px;
      transform: translateY(2px);
    }

    &-row {
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      &:hover {
        background: rgba(69, 84, 93, 0.08);
      }

      &:hover p {
        // color: white;
      }
    }
  }

  &-dropdown-item {
    width: 100%;
    height: 50px;
    padding: 0px;

    &:hover {
      background: #808a90;
    }
  }
}

@media only screen and (max-width: 860px) {
  // .header {
  //     &-container {
  //         height: auto;
  //         padding: 20px 10px;
  //     }
  // }
}
