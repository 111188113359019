.terms {
    &-container {
        height: calc(100vh - 220px);
        width: 100%;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 600px) {
    .terms {
        &-container {
            height: calc(100vh - 75px);
            width: 100%;
            overflow-y: auto;
        }
    }
    
}