.menu-item {
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  min-width: 170px;
  max-width: 170px;
  background: #ffffff;

  .image {
    display: inline-block;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 16px 3px 16px 15px;
  }

  .gebouw {
    background-color: #6C8FF0;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #6C8FF0 1px, #6C8FF0 50%);
    }
  }

  .aankoop {
    background-color: #5EDCBD;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #5EDCBD 1px, #5EDCBD 50%);
    }
  }

  .plannen {
    background-color: #FFAF55;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #FFAF55 1px, #FFAF55 50%);
    }
  }

  .afwerking {
    background-color: #5EB6DC;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #5EB6DC 1px, #5EB6DC 50%);
    }
  }

  .facturatie {
    background-color: #DE8A8A;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #DE8A8A 1px, #DE8A8A 50%);
    }
  }

  .beheer {
    background-color: #5BADFF;

    &:after {
      background: linear-gradient(to top left, transparent calc(50% - 1px), #5BADFF 1px, #5BADFF 50%);
    }
  }


  // ===== Hover =====//
  &:hover {
    .text {
      color: #ffffff;
    }

    .gebouw {
      background-color: #6C8FF0;

      &:after {
        background: #6C8FF0;
      }
    }

    .aankoop {
      background-color: #5EDCBD;

      &:after {
        background: #5EDCBD;
      }
    }

    .plannen {
      background-color: #FFAF55;

      &:after {
        background: #FFAF55;
      }
    }

    .afwerking {
      background-color: #5EB6DC;

      &:after {
        background: #5EB6DC;
      }
    }

    .facturatie {
      background-color: #DE8A8A;

      &:after {
        background: #DE8A8A;
      }
    }

    .beheer {
      background-color: #5BADFF;

      &:after {
        background: #5BADFF;
      }
    }
  }

  .text {
    display: inline-block;
    max-width: 80px;
    font-size: 14px;
    font-weight: 500;
    color: #5d5d5d;
    margin-left: 25px;
    vertical-align: super;
  }

  .text-nosuper {
    vertical-align: sub;
  }
}

.menu-item-gebouw {
  &:hover {
    background-color: #6C8FF0;
  }
}
.gebouw-selected {
  background-color: #6C8FF0;

  .text {
    color: #ffffff;
  }
}

.menu-item-aankoop {
  &:hover {
    background-color: #5EDCBD;
  }
}

.aankoop-selected {
  background-color: #5EDCBD;

  .text {
    color: #ffffff;
  }
}

.menu-item-plannen {
  &:hover {
    background-color: #FFAF55;
  }
}

.plannen-selected {
  background-color: #FFAF55;

  .text {
    color: #ffffff;
  }
}

.menu-item-afwerking {
  &:hover {
    background-color: #5EB6DC;
  }
}

.afwerking-selected {
  background-color: #5EB6DC;

  .text {
    color: #ffffff;
  }
}

.menu-item-facturatie {
  &:hover {
    background-color: #DE8A8A;
  }
}

.facturatie-selected {
  background-color: #DE8A8A;

  .text {
    color: #ffffff;
  }
}

.menu-item-beheer {
  &:hover {
    background-color: #5BADFF;
  }
}

.beheer-selected {
  background-color: #5BADFF;

  .text {
    color: #ffffff;
  }
}

.b-border {
  display: inline-block;
  position: relative;
  padding: 20px;
}

.b-border.border-right:after {
  content: "";
  position: absolute;
  right: -15px;
  border-left: none medium;
  top: 0;
  left: auto;
  width: 15px;
  height: 100%;
}
