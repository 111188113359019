.close-btn,
.back-btn {
  margin: 10px 20px;
  position: absolute;
  margin-top: 10px;
}
.close-btn {
  right: 0px;
  height: 28px;
}
.back-btn {
  display: none;
  top: 30px;
  left: 0px;
  height: 40px;
}

.title {
  font-family: 'Vietnam' !important;
  font-weight: 700 !important;
  font-size: 20px;
  color: #45545d;
}
.gridmargin {
  margin: 20px 0px;
}

.DrawerContainer,
.docinfo-row {
  padding: 20px;
}
.centerAlign {
  text-align: 'center';
  display: flex;
  justify-content: center;
  margin: '0px auto';
}
.title {
  text-align: center;
}
.fixed {
  position: relative;
}
.ContentContainer{
  height: calc(100% - 240px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.DrawerContainer {
  height: 100%;
  .btn-darkBleu,
  .btn-lightGray {
    display: flex;
    width: 150px;
  }
  .btn-lightGray {
    width: inherit;
  }
}

@media only screen and (max-width: 600px) {
  .DrawerContainer {
    padding: 10px;
    .btn-darkBleu {
      padding: 12px;
      width: 75%;
      height: 25px;  
      display: flex;
    }
    .gridmargin {
      margin: 0px;
    }
    .btn-content {
      font-size: 12px;
    }
    .btn-content .header-icons {
      margin-right: 8px;
    }
    
    .docinfo-row {
      padding: 15px;
    }
  }
  .close-btn {
    display: none;
  }
  .back-btn {
    display: block;
  }
}
.text-center {
  display: flex;
  flex-direction: column;
  margin: 0px auto !important;
}
