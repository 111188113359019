.document-info {
  &-container {
    width: auto;
    min-height: 65px;
    border-radius: 10px;

    &:hover {
      background: rgba(69, 84, 93, 0.0767045);
    }

    @media only screen and (max-width: 959px) {
      .document-type {
        position: relative;

      }
      .category-unread-dot {
        position: absolute;
        margin-left: -17px;
      }
    }
  }

  &-extension-image {
    width: 40px;
    height: 40px;
  }
}
