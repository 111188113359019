.owners {
    &-container {
        height: calc(100% - 127px);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &-project {
        &-container {
            height: 183px;
            width: 100%;
            background-color: rgb(240, 241, 242);
            display: flex;
            padding: 18px 14px;
        }

        &-icon {
            height: 22px;
            width: 22px;
            object-fit: contain;
        }

        &-title {
            &-container {
                width: 108px;
                margin-left: 25px;
            }
        }
    }
}

.owner {
    &-container {
        height: 120px;
        width: 100%;
        background-color: rgb(240, 241, 242);
        display: flex;
        padding: 18px 14px;
    }
}