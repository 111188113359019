.footer {
  &-container {
    width: auto;
    height: 55px;
    border-top: 1px solid rgba(69, 84, 93, 0.0767045);
  }

  &-logo {
    height: 28px;
  }
}

@media only screen and (max-width: 959px) {
  .footer {
    &-container {
      padding-top: 10px;
      padding-left: 10px;
      height: auto;
    }

    &-logo {
      margin-top: -15px;
      width: 160px;
    }
  }
}
