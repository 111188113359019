.purchase-info {
    &-owner {
        &-button {
            background-color: rgb(240, 241, 242);
            height: 45px;
            width: 100%;
            cursor: pointer;
            border-radius: 10px;

            & img:nth-child(2) {
                opacity: 0;
            }

            &:hover {
                background: #45545D;
            }

            &:hover p {
                color: white;
            }

            &:hover img:nth-child(1){
                opacity: 0;
            }

            &:hover img:nth-child(2){
                opacity: 1;
            }
        }

        &-icon {
            height: 22px;
            width: 22px;
            object-fit: contain;
            position: absolute;
            left: 25px;
        }
    }

    &-divider {
        background-color: #45545D;
        opacity: 0.5;
        height: 2px;
        width: 100%;
    }
}