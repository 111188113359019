.project {
  &-background {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/general/homepage.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-box {
    width: calc(100% - 90px);
    display: flex;
    flex-direction: column;

    &-container {
      border-radius: 15px;
      width: 538px;
      // background: rgba(0, 0, 0, 0.5);
      // filter: blur(8px);
      padding: 45px 0px;
    }

    &-layer {
      height: 100%;
      width: 100%;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .5);
      position: absolute;
      left: 0%;
      top: 0%;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, .5);
      backdrop-filter: blur(12px);
    }
  }

  &-logo {
    height: 64px;
    object-fit: contain;
  }


  &-input {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    margin-left: 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5)
    }

    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5)
    }

    &-container {
      height: 40px;
      width: 100%;
      position: relative;

      &-main {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      }

      &-layer {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0%;
        top: 0%;
        border-bottom: solid 1px red;
        transform-origin: left;
      }
    }

    &-icon {
      height: 25px;
      object-fit: contain;
      opacity: 0.5;
    }
  }

  &-button {
    height: 45px;
    border-radius: 5px;
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
      background-color: #2EA0B4;
    }

    &:hover p:nth-child(1) {
      color: white;
    }
  }
}

.project-heading {
  width: 100%;
  padding: 15px;
  color: #45545D;
  background: white;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;

  &:hover {
    background: #2EA0B4;
    color: #F0F1F2;
    cursor: pointer;
  }

  @media only screen and (min-width: 550px) {
    .project-title {
      margin-left: -25px;
    }
  }


  .messagesDot {
    text-align: center;
    border-radius: 50px;
    background: #D40000;
    padding: 1px 4px 4px;
    width: 15px;
    color: white;
    font-size: 12px;
  }
}
