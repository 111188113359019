.switch {
    height: 20px;
    width: 20px;
    border-radius: 12px;
    background: rgb(45, 160, 179);
    border: solid 1px rgb(45, 160, 179);
    position: absolute;
    top: -1px;
    left: -1px;
    
    &-container {
        height: 20px;
        width: 38px;
        border: solid 0.5px rgb(200, 200, 200);
        background: rgba(45, 160, 179, 0.13);
        border-radius: 10px;
        position: relative;
        cursor: pointer;
    }
}