@import "../assets/styles/scss/design";

// partial imports here
@import "../components/space/space";
@import "../components/checkbox/checkbox";
@import "auth/login";
@import "../components/error-message/error-message";
@import "dashboard/layout/footer/footer";
@import "dashboard/layout/header/header";
@import '~rc-tooltip/assets/bootstrap';
@import "dashboard/layout/content/profile/profile";
@import "dashboard/layout/content/profile/purchase_info/purchase_info";
@import "dashboard/layout/content/privacy/privacy";
@import "../components/switch/switch";
@import "dashboard/layout/content/terms/terms";
@import "dashboard/layout/content/sidecard/sidecard";
@import "dashboard/layout/content/sidecard/messages/messages";
@import "dashboard/layout/content/sidecard/notification/notification";
@import "dashboard/layout/content/sidecard/renew_password/renew_password";
@import "dashboard/layout/content/sidecard/owners/owners";
@import "dashboard/layout/content/sidecard/project/project";
@import "auth/forgot_password/forgot_password";
@import "auth/reset_password/reset_password";
@import "../components/error_modal/error_modal";;

body {
  // overflow: scroll
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  // -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #fff;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #fff;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #fff;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #fff;
}

.rc-tooltip-inner {
  background-color: #fff;
  color: #46535c;
  font-size: 15px;
  padding: 20px 30px;
}

.font-color {
  &-black {
    color: black;
  }
  &-darkBleu{
    color:var(--darkBleu);
  }

  &-white {
    color: white;
  }

  &-grey {
    color: #9b9b9b;
  }

  &-dark-grey {
    color: #4a4a4a;
  }

  &-blue-grey {
    color: #45545D;
  }

  &-blue {
    color: #2DA0B3;
  }
}

.background-color {
  &-white {
    background: #ffffff;
  }

  &-faded-faint-grey {
    background: #F4F6F9;
  }

  &-light-blue-grey {
    background: #808a90;
  }

  &-dark-grey {
    background: #46535c;
  }
}

.container-full {
  height: 100vh;
  width: 100vw;
}

.link-underline-darkBlue{

  color:var(--darkBleu);

  text-decoration: underline ;
}
