.notification {
    &-list {
        &-container {
            height: calc(100% - 127px);
            width: 100%;
            overflow-y: auto;
        }

        &-item {
            height: 120px;
            width: 100%;
            display: flex;

            &:hover {
                background-color: RGB(240, 241, 242);
            }

            &-line {
                height: calc(100% - 30px);
                width: 2px;
                background: rgb(220, 240, 243);

                &-container {
                    width: 30px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            &-dot {
                height: 14px;
                width: 14px;
                background: rgb(45, 160, 179);
                border-radius: 15px;
                border: solid 8px rgba(220, 240, 243, 1); 
            }
        }
    }
}