.privacy {
    &-container {
        height: calc(100vh - 220px);
        width: 100%;
        overflow-y: auto;
    }

    &-box {
        width: 100%;
        padding: 24px;
        border: solid 1px rgba(196, 203, 213, 0.5);
        background: white;
        border-radius: 10px;

        &-item {
            width: 100%;
            display: flex;

            &-switch {
                flex-shrink: 0;
                margin-right: 30px;
                margin-top: 5px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .privacy {
        &-container {
            height: calc(100vh - 75px);
        }
    }
}