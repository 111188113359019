.project {
    &-container {
        height: calc(100% - 127px);
        width: 100%;
        overflow-y: auto;
    }

    &-button {
        height: 45px;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;

        &-active {
            @extend .project-button;
            background-color: rgb(240, 241, 242);
        }

        &-inactive {
            @extend .project-button;
            background-color: rgb(46, 160, 180);
        }
    }

    &-divider {
        height: 1px;
        width: 100%;
        background-color: rgba(69, 84, 93, 0.5);
    }
}