.message-panel {
    &-container {
        height: calc(100vh - 127px);
        width: 100%;
    }

    &-active {
        &-document {
            height: 49px;
            width: 100%;
            background: rgb(240, 241, 242);
        }
    }

    &-box {
        max-width: 374px;
        width: 100%;
        height: auto;
        padding: 12px;

        &-grey {
            @extend .message-panel-box;
            border-radius: 0 10px 10px 10px;
            background-color: rgb(69, 84, 93);
        }

        &-blue {
            @extend .message-panel-box;
            border-radius: 10px 0px 10px 10px;
            background-color: rgb(46, 160, 180);
        }

        &-container {
            height: calc(100% - 168px);
            width: 100%;
            overflow-y: auto;
        }
    }

    &-input {
        height: 100%;
        width: 100%;
        margin-right: 13px;
        border-radius: 5px;
        background-color: rgb(240, 241, 242);
        display: flex;

        &-container {
            height: 45px;
            width: 100%;
            margin-top: 15px;
            display: flex;
        }

        &-button {
            height: 100%;
            width: 45px;
            border-radius: 5px;
            flex-shrink: 0;
            background-color: rgb(46, 160, 180);
            cursor: pointer;

            &-icon {
                height: 22px;
                width: 22px;
                object-fit: contain;
            }
        }

        &-emoji {
            height: 45px;
            width: 45px;
            flex-shrink: 0;

            &-container {
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 330px;
            }
        }

        &-field {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
        }
    }
}