.profile {
    &-container {
        height: calc(100vh - 224px);
        width: 100%;
        background-color: white;
        overflow-y: auto;
    }

    &-details {

        &-container {
            height: calc(442px - 38px);
            width: 80%;
            border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            padding: 19px 33px;
        }

        &-password {
            width: 100%;

            &-container {
                width: 100%;
                display: flex;
                align-items: center;
            }

            &-show {
                width: 22px;
                height: 22px;
                cursor: pointer;
            }

            &-renew {
                padding: 5px 10px;
                border-radius: 5px;
                background-color: rgb(240, 241, 242);
                cursor: pointer;

                &:hover {
                    background-color: #45545D;
                }

                &:hover p {
                    color: white;
                }
            }
        }
    }

    &-preferences {

        &-container {
            width: 80%;
            height: calc(201px - 38px);
            border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            padding: 19px 33px;
        }
    }

    &-purchases {

        &-container {
            height: calc(729px - 38px);
            width: 80%;
            border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            padding: 19px 33px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &-editable {
        &:focus {
            border-bottom: solid 1px #9b9b9b;
        }

        &-zip {
            width: 45px;

            &:focus {
                border-bottom: solid 1px #9b9b9b;
            }
        }

        &-email {
            max-width: 100%;
            width: 300px;
        }
    }

    &-textarea {
        height: fit-content;
        width: 100%;
        min-height: 60px;
        resize: none;
        border: none;
        outline: none;

        &:focus {
            border-bottom: solid 1px #9b9b9b;
        }
    }

    &-title {
        &-dropdown {
            width: 100%;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 0px 7px 0px rgba(147, 147, 147, 0.5);
            position: absolute;
            top: calc(100% + 10px);
            overflow: hidden;
            transform-origin: top;

            &-container {
                width: 125px;
                position: relative;
            }

            &-item {
                height: 35px;
                width: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding-left: 10px;

                &:hover {
                    background-color: RGB(240, 241, 242);
                }
            }
        }

        &-selected {
            width: 100%;
            height: 21px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-arrow {
                height: 15px;
                width: 15px;
                object-fit: contain;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .profile {
        &-container {
            width: 95%;
            margin-left: 2.5%;
            height: calc(100vh - 75px);
        }

        &-details {
            &-container {
                height: auto;
            }
        }

        &-preferences {
            &-container {
                height: auto;
            }
        }

        &-purchases {
            &-container {
                height: auto;
            }
        }
    }
}